body {
  margin: 0;
  font-family: Lato;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html, body {
  height: 100% !important;
}

.full-height-container {
  height: 100% !important;
}

.item-col:hover .bgItemText {
  background-color: #F1E93B !important;
}